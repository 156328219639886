import React from "react"
import PropTypes from "prop-types"
import { colors, fontSizes } from "../../structure/theme"
import styled from "styled-components"
import { ProductPriceMemo } from "../productPrice.component"
import { usePriceMention, usePriceText, useProductDocumentUrl } from "../product.utils"
import RichContent from "../../structure/theme/richContent.component"
import { Button } from "../../structure/theme/button.component"
import { useTranslation } from "../../translation/translate.component"
import { DownloadLink } from "../../download/downloadLink.component"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors(`grey.g700`)};
`

const ProductTitle = styled.h3`
  margin-bottom: 0;
  font-size: ${fontSizes(`regular`)};
  font-weight: 900;
  color: ${colors(`grey.g800`)};
`

const PriceMention = styled.p`
  font-size: ${fontSizes(`small`)};
  color: ${colors(`grey.g800`)};
  margin: 5px 0 20px 0;
`

const RichContentWrapper = styled.div`
  margin-bottom: 10px;
`

const DocumentLink = styled(DownloadLink)`
  margin-bottom: 5px;
  font-size: ${fontSizes(`small`)};
  color: ${colors(`grey.g700`)};
`

const OfferLink = styled.span`
  text-decoration: underline;
  font-size: ${fontSizes(`regular`)};
  font-weight: 700;
  cursor: pointer;
  color: ${colors(`grey.g800`)};
`

const ButtonUpsell = styled(Button)`
  text-transform: uppercase;
`

const ButtonWrapper = styled.div`
  width: fit-content;
  margin: 15px 0;
`

const UpsellTitle = styled.div`
  margin: 0 0 10px 0;

  * {
    margin: 0;
    font-size: ${fontSizes(`x_large`)};
    color: ${colors(`primary`)};
  }
`

export default function ProductUpsell ({ product, productUpsell, hook, onClickProduct, onClickUpsell }) {
  const { t } = useTranslation()
  const { originalId, impactProduct, universDescription } = productUpsell
  const priceMention = usePriceMention(productUpsell)
  const priceText = usePriceText(product)
  const ipidSheetUrl = useProductDocumentUrl(originalId, `ipidSheet`)
  const cguSheetUrl = useProductDocumentUrl(originalId, `cguSheet`)
  const commercialSheetUrl = useProductDocumentUrl(originalId, `commercialSheet`)

  return (
    <Container data-testid="payment_upsell_modal">
      <UpsellTitle data-testid="payment_upsell_title" dangerouslySetInnerHTML={{ __html: hook }} />
      <Hr />
      <ProductTitle data-testid="payment_upsell_product_title">{impactProduct.name}</ProductTitle>
      <ProductPriceMemo product={productUpsell} />
      <PriceMention data-testid="payment_upsell_price_mention">{priceMention}</PriceMention>
      <RichContentWrapper data-testid="payment_upsell_rich_content">
        <RichContent styledBullets content={universDescription} />
      </RichContentWrapper>
      <DocumentLink href={cguSheetUrl} data-testid="payment_upsell_link_cgUrl">
        {t(`common:product.modal_upsell_cgu_link`)}
      </DocumentLink>
      <DocumentLink href={ipidSheetUrl} data-testid="payment_upsell_link_ipidUrl">
        {t(`common:product.modal_upsell_ipid_link`)}
      </DocumentLink>
      {commercialSheetUrl && (
        <DocumentLink href={ipidSheetUrl} data-testid="payment_upsell_link_commercial">
          {t(`common:product.modal_upsell_commercial_link`)}
        </DocumentLink>
      )}
      <ButtonWrapper data-testid="payment_upsell_button">
        <ButtonUpsell onClick={onClickUpsell}>{t(`common:product.modal_upsell_button`)}</ButtonUpsell>
      </ButtonWrapper>
      <OfferLink onClick={onClickProduct} data-testid="payment_upsell_offer_link">
        {t(`common:product.modal_upsell_offer_link`, { name: product.name, priceText })}
      </OfferLink>
    </Container>
  )
}

ProductUpsell.propTypes = {
  onClickProduct: PropTypes.func,
  onClickUpsell: PropTypes.func,
  productUpsell: PropTypes.shape({
    originalId: PropTypes.number.isRequired,
    impactProduct: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    universDescription: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    impactProduct: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  hook: PropTypes.string.isRequired,
}
